import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, PaymentStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		clientId: s.string(),
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetPaymentsResponseStruct = s.array(PaymentStruct);
export type GetPaymentsResponse = s.Infer<typeof GetPaymentsResponseStruct>;

export type GetPaymentsMethodParams = {
	clientId: string;
	shopId: string;
};

export function getPayments(params: GetPaymentsMethodParams): APIRequest {
	const { clientId, shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/trade/${clientId}/payment/${shopId}`,
		{
			path: { clientId, shopId }
		},
		ApiParamStructs
	);
}
