import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		clientId: s.string(),
		shopId: s.string(),
		paymentId: s.string(),
	}),
	query: s.object({
		contentType: s.string(),
	}),
	body: EmptyObjectStruct
};

export const GetPaymentPdfResponseStruct = s.instance(ArrayBuffer);
export type GetPaymentPdfResponse = s.Infer<typeof GetPaymentPdfResponseStruct>;

export type GetPaymentPdfMethodParams = {
	clientId: string;
	shopId: string;
	paymentId: string;
};

export function getPaymentPdf(params: GetPaymentPdfMethodParams): APIRequest {
	const { clientId, shopId, paymentId } = params;
	return createAPIRequest(
		'get',
		uri`/api/trade/${clientId}/payment/${shopId}/${paymentId}`,
		{
			path: { clientId, shopId, paymentId },
			query: { contentType: 'pdf' },
		},
		ApiParamStructs,
		{ responseType: 'arraybuffer' },
	);
}
