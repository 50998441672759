import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser from '../createParser';
import { EmptyObjectStruct, PageInfoStruct, PageParamsMustStruct, RewardRequestStruct, queryBoolean, queryNumber } from '../structs';
import { uri } from '../uri';

// putRewardRequestTransact で利用
export const GetRewardRequestsRequestQuery = s.assign(
	s.type({
		orderNumber: s.optional(s.string()),
		rewardId: s.optional(s.string()),
		rewardName: s.optional(s.string()),
		ordererName: s.optional(s.string()),
		isTransacted: s.optional(queryBoolean),
		transactDateFrom: s.optional(queryNumber),
		transactDateTo: s.optional(queryNumber),
	}),
	PageParamsMustStruct,
);
const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: GetRewardRequestsRequestQuery,
	body: EmptyObjectStruct
};

export const GetRewardRequestsResponseStruct = s.object({
	pageInfo: PageInfoStruct,
	data: s.array(
		RewardRequestStruct
	),
});
export type GetRewardRequestsResponse = s.Infer<typeof GetRewardRequestsResponseStruct>;
export type GetRewardRequestsResponseValues = s.Infer<typeof RewardRequestStruct>;
export const parseGetRewardRequestsQuery = createParser(ApiParamStructs.query);

export type GetRewardRequestsMethodParams = s.Infer<typeof PageParamsMustStruct> & {
	shopId: string;
    orderNumber?: string;
    rewardId?: string;
	rewardName?: string;
	ordererName?: string;
	isTransacted?: boolean;
	transactDateFrom?: number;
	transactDateTo?: number;
};

export function getRewardRequests(params: GetRewardRequestsMethodParams): APIRequest {
	const { shopId, ...query } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/request`,
		{
			path: { shopId },
			query: Object.fromEntries(Object.entries(query || {}).filter(([,v]) => v !== undefined)),
		},
		ApiParamStructs
	);
}
