import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { PageInfo } from '@sasagase/types';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Column, DataTable } from '../../organisms/DataTable';

interface TargetListTableProps {
	rows?: APIResponses.GetTargetsResponseValues[];
	pageInfo?: PageInfo;
	isLoading: boolean;
	methodFilter: UseFormMethods;
	loadMore: () => void;
	onChangeCanSendMail: (target: APIResponses.GetTargetsResponseValues, canSendMail: boolean) => void;
	toOrderDetailURL: (orderNumber: string) => string;
}

export const TargetListTable: React.FunctionComponent<TargetListTableProps> = (props) => {

	// テーブル固有のCSS指定
	// TODO ここ以外で指定できないか要検討
	const tableStyle = css`
		height: 560px;

		&::-webkit-scrollbar-track {
			margin-top: 51px;
		}

		th:nth-of-type(1),
		th:nth-of-type(2) {
			padding-top: 16px;
			padding-bottom: 16px;
			border-top: none;
		}
	`;

	const handleChangeCanSendMail = (target: APIResponses.GetTargetsResponseValues) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeCanSendMail(target, ev.currentTarget.checked);
	};

	const orderNumberBodyTemplate = (rowData: any) => {
		return <>
			<ul>
				{rowData.orders.map((order: any) =>
					<li key={order.number}><a href={props.toOrderDetailURL(order.number)} target="_blank" rel="noreferrer">{order.number}</a></li>
				)}
			</ul>
		</>;
	};
	const canSendingMailBodyTemplate = (rowData: any) => {
		return (
			<label className="el_toggleBtn">
				<input type="checkbox" checked={rowData.orders.some((order: any) => order.canSendingMail)} onChange={handleChangeCanSendMail(rowData)} />
				<span />
			</label>
		);
	};
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	}

	return (
		<>
			<DataTable css={tableStyle}
				rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
				loadMore={props.loadMore}
				paginationType="infiniteScroll"
				currentPageReportTemplate={pageReportTemplate}
				>
				<Column style="0 1 754px center flex-start" field="orderNumber" bodyTemplate={orderNumberBodyTemplate}>注文番号</Column>
				<Column style="0 0 110px center center" field="canSendingMail" bodyTemplate={canSendingMailBodyTemplate}>メール送信</Column>
			</DataTable>
		</>
	);
}
export default TargetListTable;