import { MASTER_ENTITY_ID } from '@sasagase/types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useHeader } from './useHeader';

interface HeaderProps {
	onClickBurger: () => void;
	basePath: string;
}

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
	const {
		state,
		open,
		handleClickMenu,
		handleClickShop,
		handleClickManagement,
		handleClickLogout,
		handleClickGuide,
		handleClickContact,
	} = useHeader(props.basePath);

	const getShopName = (shopId: string) => state.shops?.[shopId]?.name || shopId;
	const selectShops = state.user?.getEnableCaps('yReview_login').filter(cap => cap.entityId !== MASTER_ENTITY_ID) || [];

	return (
		<header className="ly_header">
			<div className="ly_header_inner ly_header_inner__ttl">
				<button className="el_navToggle menu" type="button" onClick={props.onClickBurger} />
				<p className="el_systemName">
					<picture>
						<img src="/assets/img/logo-c-1.svg" alt="ラクラクあつまレビュー" />
					</picture>
				</p>

				<div className="el_links">
					<a href="#" className="el_linkIcon el_linkIcon__guideL" onClick={handleClickGuide}><span><br className="br" />ご利用<br className="br" />ガイド</span></a>
					<a href="#" className="el_linkIcon el_linkIcon__contactL" onClick={handleClickContact}><span><br className="br" />お問い<br className="br" />合わせ</span></a>
				</div>
			</div>
			<ul className="ly_header_inner ly_header_inner__menu">
				<li className={`bl_pullDown bl_pullDown__shop ${open == 'shop' ? 'is_open' : ''}`}>
					<span className="bl_pullDown_selected" onClick={handleClickMenu('shop')}>{getShopName(state.params?.shopId)}</span>
					<ul className="bl_pullDown_list">
						<li className="bl_pullDown_item"><span>店舗切り替え</span></li>
						{
							selectShops.map(cap =>
								<li className="bl_pullDown_item" key={cap.entityId}>
									<a href="#" onClick={handleClickShop(cap.entityId)}>{getShopName(cap.entityId)}</a>
								</li>
							)
						}
						<li className="bl_pullDown_item"><span>プラン・請求情報</span></li>
						<li className="bl_pullDown_item"><Link to={props.basePath + '/shop/plan'}>{getShopName(state.params?.shopId)} のプラン・請求情報</Link></li>
					</ul>
				</li>
				<li className={`bl_pullDown bl_pullDown__user ${open == 'user' ? 'is_open' : ''}`}>
					<span className="bl_pullDown_selected" onClick={handleClickMenu('user')}>{state.user?.name}</span>
					<ul className="bl_pullDown_list">
						<li className="bl_pullDown_item"><a href="#" onClick={handleClickManagement}>ユーザー管理</a></li>
						<li className="bl_pullDown_item"><a href="#" onClick={handleClickLogout}>ログアウト</a></li>
					</ul>
				</li>
			</ul>
		</header>
	);
};
export default Header;