import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { ClientStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		clientId: s.string(),
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetClientResponseStruct = s.nullable(ClientStruct);
export type GetClientResponse = s.Infer<typeof GetClientResponseStruct>;

export type GetClientMethodParams = {
	clientId: string;
	shopId: string;
};

export function getClient(params: GetClientMethodParams): APIRequest {
	const { clientId, shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/trade/${clientId}/client/${shopId}`,
		{
			path: { clientId, shopId }
		},
		ApiParamStructs
	);
}
