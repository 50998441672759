import { collectServiceStatus, paymentMethod, paymentStatus } from "@sasagase/types";
import * as s from 'superstruct';

export const ClientStruct = s.type({
	code: s.string(),
	mail: s.string(),
	name: s.string(),
	nameKana: s.optional(s.string()),
	zip: s.optional(s.string()),
	address: s.optional(s.string()),
	building: s.optional(s.string()),
	phone: s.optional(s.string()),
	bankCode: s.optional(s.string()),
	branchCode: s.optional(s.string()),
	accountType: s.enums(['1', '2']),
	accountNumber: s.optional(s.string()),
	accountHolder: s.optional(s.string()),
	collectServiceStatus: s.enums(collectServiceStatus),
});

export const PaymentStruct = s.type({
	clientId: s.string(),
	service: s.string(),
	sourceId: s.string(),
	date: s.number(),
	until: s.optional(s.number()),
	plan: s.optional(s.string()),
	period: s.optional(s.enums(['monthly', 'yearly'])),
	amount: s.number(),
	status: s.enums(paymentStatus),
	method: s.enums(paymentMethod),
	amountConfirmed: s.optional(s.boolean()),
});