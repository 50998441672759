import { PAYMENT_DEFAULT_PLANS, PAYMENT_PLANS } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { PageTitle } from '../../molecules/PageTitle';
import useShopPlan from './useShopPlan';

const defaultPlan = PAYMENT_DEFAULT_PLANS.yReview;
const paymentPlans = PAYMENT_PLANS.yReview;

const initValues = {
	paymentId: '',
};
export type FormValues = typeof initValues;

export const ShopPlan: React.FunctionComponent = () => {
	const {
		shop,
		shopEntity,
		client,
		payments,
		loading,
		methods,
		handleClickDownloadPDF,
	} = useShopPlan(initValues);

	if (!shop || !shopEntity || !client) {
		return null;
	}

	const { register } = methods;

	const plan = shopEntity.plan ?? defaultPlan;
	const planInfo = paymentPlans[plan] ?? {};

	const showZip = (zip = '') => {
		if (zip.length === 7) {
			return `${zip.substring(0, 3)}-${zip.substring(3)}`;
		}
		return zip;
	};

	return (
		<>
			<PageTitle>プラン・請求情報</PageTitle>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row">
							<FormLabel headline>プラン</FormLabel>
							<table className="bl_table bl_table__plan">
								<tbody className="bl_table_body">
									<tr>
										<td>現在のプラン</td>
										<td>{planInfo.name}</td>
									</tr>
									<tr>
										<td>料金</td>
										<td>
											{planInfo.period === 'monthly' ? '月額 ' : '年額 '}
											{planInfo.amount.toLocaleString()} 円
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="bl_panel_row">
							<FormLabel headline>請求</FormLabel>
							<table className="bl_table bl_table__plan">
								<tbody className="bl_table_body">
									<tr>
										<td>契約者情報</td>
										<td>
											{client.name}<br />
											〒{showZip(client.zip)} {client.address} {client.building}<br />
											{client.phone}
										</td>
									</tr>
									<tr>
										<td>請求書</td>
										<td>
											<div>
												<div className="el_selectWrap">
													<select name="paymentId" ref={register} disabled={loading}>
														<option value="" disabled hidden>ダウンロードする請求書を選択してください</option>
														{payments.map(payment => 
															<option key={payment.id} value={payment.id}>{dayjs(payment.date).format('YYYY年MM月')}システム利用料</option>
														)}
													</select>
												</div>
												<button className="el_btnBlueInv mt_16" type="button" onClick={handleClickDownloadPDF} disabled={loading}>{loading ? 'ダウンロード中' : 'ダウンロード'}</button>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default ShopPlan;