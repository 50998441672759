import { Client, Payment, ShopEntity } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAPI, useAppState } from "../../../context";
import { saveFile } from "../RequestList/saveCSVFile";
import { FormValues } from "./ShopPlan";

export const useShopPlan = (initValues: FormValues) => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const shop = state.shop;
	const callAPI = useAPI();
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const [shopEntity, setShopEntity] = React.useState<ShopEntity|null>(null);
	const [client, setClient] = React.useState<Client|null>(null);
	const [payments, setPayments] = React.useState<Payment[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	const { getValues } = formMethods;

	React.useEffect(() => {
		if (!shopId) {
			return;
		}

		return callAPI.yReview.getShop({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			if (result.data) {
				const se = ShopEntity.create(result.data);
				setShopEntity(se);
			}
		});
	}, [shopId]);

	React.useEffect(() => {
		if (!shopEntity) {
			return;
		}

		const params = {
			clientId: shopEntity.clientId,
			shopId,
		};
		return callAPI.trade.getClient(params, (err, result) => {
			if (err) {
				return;
			}
			if (result.data) {
				const client = new Client(result.data);
				setClient(client);
			}
		});
	}, [shopEntity]);

	React.useEffect(() => {
		if (!shopEntity) {
			return;
		}

		const params = {
			clientId: shopEntity.clientId,
			shopId,
		};
		return callAPI.trade.getPayments(params, (err, result) => {
			if (err) {
				return;
			}
			if (result.data) {
				const payments: Payment[] = result.data.map((row: Record<string, unknown>) => new Payment(row));
				setPayments(payments.sort((a, b) => b.date - a.date));
			}
		});
	}, [shopEntity]);

	const handleClickDownloadPDF = async () => {
		if (!shop || !shopEntity || !client) {
			return;
		}
		const paymentId = getValues('paymentId');
		const payment = payments.find((payment) => payment.id === paymentId);
		if (!payment) {
			return;
		}

		setLoading(true);
		const params = {
			clientId: shopEntity.clientId,
			shopId,
			paymentId,
		}
		const result = await callAPI.trade.getPaymentPdf(params);
		if (result && result.data) {
			const blob = new Blob([result.data], { type: `application/pdf` });
			saveFile(`請求書_${dayjs(payment.date).format('YYYY年M月')}システム利用料.pdf`, blob);
		}
		setLoading(false);
	};

	return {
		shop,
		shopEntity,
		client,
		payments,
		loading,
		methods: formMethods,
		handleClickDownloadPDF,
	};
};
export default useShopPlan;