import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { Form, FormGroup, Input } from '../../organisms/Form';
import TargetListTable from './TargetListTable';
import useExclusionOrder from './useExclusionOrder';

const initValues = {
	orderNumber: '',
};

export const ExclusionOrder: React.FunctionComponent = () => {

	const {
		methods,
		handleClickChangeOrderNumber,
		tableProps,
	} = useExclusionOrder({ initValues });

	const { watch } = methods;

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>注文番号から設定</FormLabel>
				<Form methods={methods} disableBlock>
					<FormGroup>
						<FormLabel>注文番号を入力</FormLabel>
						<div className="mb_8">
							<Input type="text" name="orderNumber" />
						</div>
						<Button className="el_btnInv" type="button" onClick={handleClickChangeOrderNumber} disabled={watch('orderNumber') === ''}>表示</Button>
					</FormGroup>
					<TargetListTable
						{...tableProps}
						/>
				</Form>
			</div>
		</>
	);
};
export default ExclusionOrder;