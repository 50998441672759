import { denormalize } from '@sasagase/object-normalizer';
import { PageParams } from '@sasagase/types';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FormData } from 'formdata-node';
import * as s from 'superstruct';
import * as MailAPI from './mail';
import * as NoticeAPI from './notice';
import * as StorageAPI from './storage';
import * as TradeAPI from './trade';
import * as UserAPI from './user';
import * as YReviewAPI from './y-review';
import * as YahooAPI from './yahoo';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface AxiosRequestConfigWithResponceType<T> extends AxiosRequestConfig {}
export type APIRequest<T = any> = AxiosRequestConfigWithResponceType<T>;
export type APIResponseStruct = s.Struct<any, any>;
export type APICallback<T> = (err: null|Error|AxiosResponse<T>, result: AxiosResponse<T>) => unknown;

export type ExtractedPageParams = {
	page?: string;
	perPage?: string;
	pagination?: string;
};
export function extractPageParams(pageParams?: PageParams): ExtractedPageParams {
	const params: Record<string, string> = {};
	if (pageParams?.page) {
		params.page = String(pageParams.page);
	}
	if (pageParams?.perPage) {
		params.perPage = String(pageParams.perPage);
	}
	if (Object.keys(params).length) {
		params.pagination = "1";	// true
	}
	return params;
}
export function addPageParams<T extends Record<string, unknown>>(params: T, pageParams?: PageParams): T & ExtractedPageParams {
	return Object.assign(params, extractPageParams(pageParams));
}

/**
 * API呼び出しをする関数
 *
 * レスポンスは第2引数にコールバック関数が指定されていれば、そのコールバック関数の引数として呼び出される。
 * この場合の戻り値はAPI呼び出しをキャンセルする関数になる。
 * 第2引数が省略されていた場合、戻り値はレスポンスに解決されるPromiseになる。
 *
 * @param {APIRequest} req API呼び出しのメソッドやURL等を意味するAPIRequestオブジェクト
 * @param {APIStruct} struct APIで取得したレスポンスのバリデーション用定義
 * @param {APICallback} [func] レスポンス時に呼び出されるコールバック関数
 * @returns {Promise<AxiosResponse>|() => void} 
 *     第２引数が省略されていれば戻り値に解決されるPromise
 *     指定されていればAPI呼び出しをキャンセルする関数を返す
 *
 * @export
 * @interface APICaller
 */
export interface APICaller {
	<T>(req: APIRequest<T>, struct: APIResponseStruct): Promise<AxiosResponse<T>>;
	<T>(req: APIRequest<T>, struct: APIResponseStruct, func: APICallback<T>): () => void;
}

const RequestMethodStruct = s.enums(['get', 'post', 'put', 'delete']);
interface CreateAPIRequestParams {
	path?: Record<string, unknown>,
	query?: Record<string, unknown>,
	body?: unknown,
}
interface CreateAPIStructs {
	path: s.Struct<any, any>,
	query: s.Struct<any, any>,
	body: s.Struct<any, any>,
	/** 
	 * `multipart/form-data`の時の各フィールド値
	 * フィールドの値は`string | File`なのでstringから他の型に変換したいときは`coerce`を使用する
	 */
	formData?: Record<string, s.Struct<any, any>>,
}

export function createAPIRequest(method: 'get'|'post'|'put'|'delete', path: string, params: CreateAPIRequestParams, structs: CreateAPIStructs, config: AxiosRequestConfig = {}): APIRequest {
	s.assert(method, RequestMethodStruct);
	s.assert(params.path, structs.path);
	s.assert(params.query, structs.query);
	s.assert(params.body, structs.body);
	if (params.body instanceof FormData) {
		for (const [key, val] of params.body) {
			// createだと coerce が動作するので
			s.create(val, structs.formData?.[key] ?? s.string());
		}
	}

	const nonUndefinedQuery = Object.entries(params.query || {})
								.filter(([, v]) => v !== undefined)
								.map(([k,v]) => [k, String(v)]);
	const qp = new URLSearchParams(nonUndefinedQuery);

	return {
		method: method,
		url: `${path}` + (qp.toString() ? ('?' + qp.toString()) : ''),
		data: params.body,
		...config,
	};
}

function statusValidator(status: number): boolean {
	return status < 500;
}

export type APIMethodCaller<T, U> = {
	(params: U, func?: undefined): Promise<AxiosResponse<T> | undefined>;
	(params: U, func: APICallback<T>): () => void;
}

type CallAPIRet<T, F extends APICallback<T> | undefined> = F extends APICallback<T> ? () => void : Promise<AxiosResponse<T> | undefined>;

export function defaultFunc(...args: unknown[]) {
	console.error('Not under the provider.', ...args);
}

export class Api {

	errorCallback: (result: AxiosResponse) => unknown;
	mail: {
		getShop: APIMethodCaller<MailAPI.GetShopResponse, MailAPI.GetShopMethodParams>,
		putShop: APIMethodCaller<MailAPI.PutShopResponse, MailAPI.PutShopMethodParams>,
		sendTestMail: APIMethodCaller<MailAPI.SendTestMailResponse, MailAPI.SendTestMailMethodParams>,
	};
	notice: {
		getAlerts: APIMethodCaller<NoticeAPI.GetAlertsResponse, NoticeAPI.GetAlertsMethodParams>,
		getNotices: APIMethodCaller<NoticeAPI.GetNoticesResponse, NoticeAPI.GetNoticesMethodParams>,
		getShop: APIMethodCaller<NoticeAPI.GetShopResponse, NoticeAPI.GetShopMethodParams>,
		getWarnings: APIMethodCaller<NoticeAPI.GetWarningsResponse, NoticeAPI.GetWarningsMethodParams>
		putShop: APIMethodCaller<NoticeAPI.PutShopResponse, NoticeAPI.PutShopMethodParams>
	};
	storage: {
		postImage: APIMethodCaller<StorageAPI.PostImageResponse, StorageAPI.PostImageMethodParams>,
	};
	trade: {
		getClient: APIMethodCaller<TradeAPI.GetClientResponse, TradeAPI.GetClientMethodParams>,
		getPaymentPdf: APIMethodCaller<TradeAPI.GetPaymentPdfResponse, TradeAPI.GetPaymentPdfMethodParams>,
		getPayments: APIMethodCaller<TradeAPI.GetPaymentsResponse, TradeAPI.GetPaymentsMethodParams>,
	};
	user: {
		activateToken: APIMethodCaller<UserAPI.ActivateTokenResponse, UserAPI.ActivateTokenMethodParams>,
		changePassword: APIMethodCaller<UserAPI.ChangePasswordResponse, UserAPI.ChangePasswordMethodParams>,
		changeUser: APIMethodCaller<UserAPI.ChangeUserResponse, UserAPI.ChangeUserMethodParams>,
		deleteEntityUser: APIMethodCaller<UserAPI.DeleteEntityUserResponse, UserAPI.DeleteEntityUserMethodParams>,
		getEntityUser: APIMethodCaller<UserAPI.GetEntityUserResponse, UserAPI.GetEntityUserMethodParams>,
		getEntityUsers: APIMethodCaller<UserAPI.GetEntityUsersResponse, UserAPI.GetEntityUsersMethodParams>,
		getReviewMail: APIMethodCaller<UserAPI.GetReviewMailResponse, UserAPI.GetReviewMailMethodParams>,
		getTwoFactorAuth: APIMethodCaller<UserAPI.GetTwoFactorAuthResponse, UserAPI.GetTwoFactorAuthMethodParams>,
		getTwoFactorAuthCode: APIMethodCaller<UserAPI.GetTwoFactorAuthCodeResponse, UserAPI.GetTwoFactorAuthCodeMethodParams>,
		getUserByToken: APIMethodCaller<UserAPI.GetUserByTokenResponse, UserAPI.GetUserByTokenMethodParams>,
		postEntityUser: APIMethodCaller<UserAPI.PostEntityUserResponse, UserAPI.PostEntityUserMethodParams>,
		postUserLoginTwoFactorAuth: APIMethodCaller<UserAPI.PostUserLoginTwoFactorAuthResponse, UserAPI.PostUserLoginTwoFactorAuthMethodParams>,
		putDisableTwoFactorAuth: APIMethodCaller<UserAPI.PutDisableTwoFactorAuthResponse, UserAPI.PutDisableTwoFactorAuthMethodParams>,
		putEntityUserCapability: APIMethodCaller<UserAPI.PutEntityUserCapabilityResponse, UserAPI.PutEntityUserCapabilityMethodParams>,
		putInviteUser: APIMethodCaller<UserAPI.PutInviteUserResponse, UserAPI.PutInviteUserMethodParams>,
		putTwoFactorAuth: APIMethodCaller<UserAPI.PutTwoFactorAuthResponse, UserAPI.PutTwoFactorAuthMethodParams>,
		recoverPassword: APIMethodCaller<UserAPI.RecoverPasswordResponse, UserAPI.RecoverPasswordMethodParams>,
		resetPassword: APIMethodCaller<UserAPI.ResetPasswordResponse, UserAPI.ResetPasswordMethodParams>,
		sendReviewMail: APIMethodCaller<UserAPI.SendReviewMailResponse, UserAPI.SendReviewMailMethodParams>,
		userLogin: APIMethodCaller<UserAPI.UserLoginResponse, UserAPI.UserLoginMethodParams>,
		userLoginBySession: APIMethodCaller<UserAPI.UserLoginBySessionResponse, UserAPI.UserLoginBySessionMethodParams>,
		userLogout: APIMethodCaller<UserAPI.UserLogoutResponse, UserAPI.UserLogoutMethodParams>,
		postContact: APIMethodCaller<UserAPI.PostContactResponse, UserAPI.PostContactMethodParams>,
	};
	yReview: {
		deleteCampaign: APIMethodCaller<YReviewAPI.DeleteCampaignResponse, YReviewAPI.DeleteCampaignMethodParams>,
		deleteGroup: APIMethodCaller<YReviewAPI.DeleteGroupResponse, YReviewAPI.DeleteGroupMethodParams>,
		deleteNotCovered: APIMethodCaller<YReviewAPI.DeleteNotCoveredResponse, YReviewAPI.DeleteNotCoveredMethodParams>,
		deleteReward: APIMethodCaller<YReviewAPI.DeleteRewardResponse, YReviewAPI.DeleteRewardMethodParams>,
		deleteTemplate: APIMethodCaller<YReviewAPI.DeleteTemplateResponse, YReviewAPI.DeleteTemplateMethodParams>,
		getCampaign: APIMethodCaller<YReviewAPI.GetCampaignResponse, YReviewAPI.GetCampaignMethodParams>,
		getCampaignList: APIMethodCaller<YReviewAPI.GetCampaignListResponse, YReviewAPI.GetCampaignListMethodParams>,
		getCampaignReviewNum: APIMethodCaller<YReviewAPI.GetCampaignReviewNumResponse, YReviewAPI.GetCampaignReviewNumMethodParams>,
		getGroups: APIMethodCaller<YReviewAPI.GetGroupsResponse, YReviewAPI.GetGroupsMethodParams>,
		getNotCovered: APIMethodCaller<YReviewAPI.GetNotCoveredResponse, YReviewAPI.GetNotCoveredMethodParams>,
		getNotCovereds: APIMethodCaller<YReviewAPI.GetNotCoveredsResponse, YReviewAPI.GetNotCoveredsMethodParams>,
		getReward: APIMethodCaller<YReviewAPI.GetRewardResponse, YReviewAPI.GetRewardMethodParams>,
		getRewardRequestDetail: APIMethodCaller<YReviewAPI.GetRewardRequestDetailResponse, YReviewAPI.GetRewardRequestDetailMethodParams>,
		getRewardRequests: APIMethodCaller<YReviewAPI.GetRewardRequestsResponse, YReviewAPI.GetRewardRequestsMethodParams>,
		getRewards: APIMethodCaller<YReviewAPI.GetRewardsResponse, YReviewAPI.GetRewardsMethodParams>,
		getRewardTemplateImage: APIMethodCaller<YReviewAPI.GetRewardTemplateImageResponse, YReviewAPI.GetRewardTemplateImageMethodParams>,
		getShop: APIMethodCaller<YReviewAPI.GetShopResponse, YReviewAPI.GetShopMethodParams>,
		getShopByIds: APIMethodCaller<YReviewAPI.GetShopByIdsResponse, YReviewAPI.GetShopByIdsMethodParams>,
		getTargets: APIMethodCaller<YReviewAPI.GetTargetsResponse, YReviewAPI.GetTargetsMethodParams>,
		getTemplate: APIMethodCaller<YReviewAPI.GetTemplateResponse, YReviewAPI.GetTemplateMethodParams>,
		getTemplates: APIMethodCaller<YReviewAPI.GetTemplatesResponse, YReviewAPI.GetTemplatesMethodParams>,
		getTargetSummary: APIMethodCaller<YReviewAPI.GetTargetSummaryResponse, YReviewAPI.GetTargetSummaryMethodParams>,
		getTopReviewSummary: APIMethodCaller<YReviewAPI.GetTopReviewSummaryResponse, YReviewAPI.GetTopReviewSummaryMethodParams>,
		postCampaign: APIMethodCaller<YReviewAPI.PostCampaignResponse, YReviewAPI.PostCampaignMethodParams>,
		postGroup: APIMethodCaller<YReviewAPI.PostGroupResponse, YReviewAPI.PostGroupMethodParams>,
		postNotCovered: APIMethodCaller<YReviewAPI.PostNotCoveredResponse, YReviewAPI.PostNotCoveredMethodParams>,
		postReviews: APIMethodCaller<YReviewAPI.PostReviewsResponse, YReviewAPI.PostReviewsMethodParams>,
		postReward: APIMethodCaller<YReviewAPI.PostRewardResponse, YReviewAPI.PostRewardMethodParams>,
		postTemplate: APIMethodCaller<YReviewAPI.PostTemplateResponse, YReviewAPI.PostTemplateMethodParams>,
		putCampaign: APIMethodCaller<YReviewAPI.PutCampaignResponse, YReviewAPI.PutCampaignMethodParams>,
		putGroup: APIMethodCaller<YReviewAPI.PutGroupResponse, YReviewAPI.PutGroupMethodParams>,
		putNotCovered: APIMethodCaller<YReviewAPI.PutNotCoveredResponse, YReviewAPI.PutNotCoveredMethodParams>,
		putOrderCanSendingMail: APIMethodCaller<YReviewAPI.PutOrderCanSendingMailResponse, YReviewAPI.PutOrderCanSendingMailMethodParams>,
		putReward: APIMethodCaller<YReviewAPI.PutRewardResponse, YReviewAPI.PutRewardMethodParams>,
		putRewardRequestTransact: APIMethodCaller<YReviewAPI.PutRewardRequestTransactResponse, YReviewAPI.PutRewardRequestTransactMethodParams>,
		putShop: APIMethodCaller<YReviewAPI.PutShopResponse, YReviewAPI.PutShopMethodParams>,
		putTemplate: APIMethodCaller<YReviewAPI.PutTemplateResponse, YReviewAPI.PutTemplateMethodParams>,
		setCampaignPriority: APIMethodCaller<YReviewAPI.SetCampaignPriorityResponse, YReviewAPI.SetCampaignPriorityMethodParams>,
	};
	yahoo: {
		beginOAuth: APIMethodCaller<YahooAPI.BeginOAuthResponse, YahooAPI.BeginOAuthMethodParams>,
		checkLogin: APIMethodCaller<YahooAPI.CheckLoginResponse, YahooAPI.CheckLoginMethodParams>,
		finishOAuth: APIMethodCaller<YahooAPI.FinishOAuthResponse, YahooAPI.FinishOAuthMethodParams>,
		getLicenseExpiryDate: APIMethodCaller<YahooAPI.GetLicenseExpiryDateResponse, YahooAPI.GetLicenseExpiryDateMethodParams>,
		getShop: APIMethodCaller<YahooAPI.GetShopResponse, YahooAPI.GetShopMethodParams>,
		getShops: APIMethodCaller<YahooAPI.GetShopsResponse, YahooAPI.GetShopsMethodParams>,
		getShopTokenExpiration: APIMethodCaller<YahooAPI.GetShopTokenExpirationResponse, YahooAPI.GetShopTokenExpirationMethodParams>,
		putShop: APIMethodCaller<YahooAPI.PutShopResponse, YahooAPI.PutShopMethodParams>,
		searchItems: APIMethodCaller<YahooAPI.SearchItemsResponse, YahooAPI.SearchItemsMethodParams>,
		validateCoupon: APIMethodCaller<YahooAPI.ValidateCouponResponse, YahooAPI.ValidateCouponMethodParams>,
	};

	constructor(errorCallback: (result: AxiosResponse) => unknown) {
		this.errorCallback = errorCallback;
		this.mail = {
			getShop: (params, func?): any => {
				return this.callAPI(MailAPI.getShop(params), MailAPI.GetShopResponseStruct, func);
			},
			putShop: (params, func?): any => {
				return this.callAPI(MailAPI.putShop(params), MailAPI.PutShopResponseStruct, func);
			},
			sendTestMail: (params, func?): any => {
				return this.callAPI(MailAPI.sendTestMail(params), MailAPI.SendTestMailResponseStruct, func);
			},
		};
		this.notice = {
			getAlerts: (params, func?): any => {
				return this.callAPI(NoticeAPI.getAlerts(params), NoticeAPI.GetAlertsResponseStruct, func);
			},
			getNotices: (params, func?): any => {
				return this.callAPI(NoticeAPI.getNotices(params), NoticeAPI.GetNoticesResponseStruct, func);
			},
			getShop: (params, func?): any => {
				return this.callAPI(NoticeAPI.getShop(params), NoticeAPI.GetShopResponseStruct, func);
			},
			getWarnings: (params, func?): any => {
				return this.callAPI(NoticeAPI.getWarnings(params), NoticeAPI.GetWarningsResponseStruct, func);
			},
			putShop: (params, func?): any => {
				return this.callAPI(NoticeAPI.putShop(params), NoticeAPI.PutShopResponseStruct, func);
			},
		};
		this.storage = {
			postImage: (params, func?): any => {
				return this.callAPI(StorageAPI.postImage(params), StorageAPI.PostImageResponseStruct, func);
			}
		};
		this.trade = {
			getClient: (params, func?): any => {
				return this.callAPI(TradeAPI.getClient(params), TradeAPI.GetClientResponseStruct, func);
			},
			getPaymentPdf: (params, func?): any => {
				return this.callAPI(TradeAPI.getPaymentPdf(params), TradeAPI.GetPaymentPdfResponseStruct, func);
			},
			getPayments: (params, func?): any => {
				return this.callAPI(TradeAPI.getPayments(params), TradeAPI.GetPaymentsResponseStruct, func);
			},
		};
		this.user = {
			activateToken: (params, func?): any => {
				return this.callAPI(UserAPI.activateToken(params), UserAPI.ActivateTokenResponseStruct, func);
			},
			changePassword: (params, func?): any => {
				return this.callAPI(UserAPI.changePassword(params), UserAPI.ChangePasswordResponseStruct, func);
			},
			changeUser: (params, func?): any => {
				return this.callAPI(UserAPI.changeUser(params), UserAPI.ChangeUserResponseStruct, func);
			},
			deleteEntityUser: (params, func?): any => {
				return this.callAPI(UserAPI.deleteEntityUser(params), UserAPI.DeleteEntityUserResponseStruct, func);
			},
			getEntityUser: (params, func?): any => {
				return this.callAPI(UserAPI.getEntityUser(params), UserAPI.GetEntityUserResponseStruct, func);
			},
			getEntityUsers: (params, func?): any => {
				return this.callAPI(UserAPI.getEntityUsers(params), UserAPI.GetEntityUsersResponseStruct, func);
			},
			getReviewMail: (params, func?): any => {
				return this.callAPI(UserAPI.getReviewMail(params), UserAPI.GetReviewMailResponseStruct, func);
			},
			getUserByToken: (params, func?): any => {
				return this.callAPI(UserAPI.getUserByToken(params), UserAPI.GetUserByTokenResponseStruct, func);
			},
			getTwoFactorAuth: (_, func?): any => {
				return this.callAPI(UserAPI.getTwoFactorAuth(), UserAPI.GetTwoFactorAuthResponseStruct, func);
			},
			getTwoFactorAuthCode: (_, func?): any => {
				return this.callAPI(UserAPI.getTwoFactorAuthCode(), UserAPI.GetTwoFactorAuthCodeResponseStruct, func);
			},
			postEntityUser: (params, func?): any => {
				return this.callAPI(UserAPI.postEntityUser(params), UserAPI.PostEntityUserResponseStruct, func);
			},
			postUserLoginTwoFactorAuth: (params, func?): any => {
				return this.callAPI(UserAPI.postUserLoginTwoFactorAuth(params), UserAPI.PostUserLoginTwoFactorAuthResponseStruct, func);
			},
			putDisableTwoFactorAuth: (params, func?): any => {
				return this.callAPI(UserAPI.PutDisableTwoFactorAuth(params), UserAPI.PutDisableTwoFactorAuthResponseStruct, func);
			},
			putEntityUserCapability: (params, func?): any => {
				return this.callAPI(UserAPI.putEntityUserCapability(params), UserAPI.PutEntityUserCapabilityResponseStruct, func);
			},
			putInviteUser: (params, func?): any => {
				return this.callAPI(UserAPI.putInviteUser(params), UserAPI.PutInviteUserResponseStruct, func);
			},
			putTwoFactorAuth: (params, func?): any => {
				return this.callAPI(UserAPI.putTwoFactorAuth(params), UserAPI.PutTwoFactorAuthResponseStruct, func);
			},
			recoverPassword: (params, func?): any => {
				return this.callAPI(UserAPI.recoverPassword(params), UserAPI.RecoverPasswordResponseStruct, func);
			},
			resetPassword: (params, func?): any => {
				return this.callAPI(UserAPI.resetPassword(params), UserAPI.ResetPasswordResponseStruct, func);
			},
			sendReviewMail: (params, func?): any => {
				return this.callAPI(UserAPI.sendReviewMail(params), UserAPI.SendReviewMailResponseStruct, func);
			},
			userLogin: (params, func?): any => {
				return this.callAPI(UserAPI.userLogin(params), UserAPI.UserLoginResponseStruct, func);
			},
			userLoginBySession: (params, func?): any => {
				return this.callAPI(UserAPI.userLoginBySession(params), UserAPI.UserLoginBySessionResponseStruct, func);
			},
			userLogout: (params, func?): any => {
				return this.callAPI(UserAPI.userLogout(params), UserAPI.UserLogoutResponseStruct, func);
			},
			postContact: (params, func?): any => {
				return this.callAPI(UserAPI.postContact(params), UserAPI.PostContactResponseStruct, func);
			},
		};
		this.yReview = {
			deleteCampaign: (params, func?): any => {
				return this.callAPI(YReviewAPI.deleteCampaign(params), YReviewAPI.DeleteCampaignResponseStruct, func);
			},
			deleteGroup: (params, func?): any => {
				return this.callAPI(YReviewAPI.deleteGroup(params), YReviewAPI.DeleteGroupResponseStruct, func);
			},
			deleteNotCovered: (params, func?): any => {
				return this.callAPI(YReviewAPI.deleteNotCovered(params), YReviewAPI.DeleteNotCoveredResponseStruct, func);
			},
			deleteReward: (params, func?): any => {
				return this.callAPI(YReviewAPI.deleteReward(params), YReviewAPI.DeleteRewardResponseStruct, func);
			},
			deleteTemplate: (params, func?): any => {
				return this.callAPI(YReviewAPI.deleteTemplate(params), YReviewAPI.DeleteTemplateResponseStruct, func);
			},
			getCampaign: (params, func?): any => {
				return this.callAPI(YReviewAPI.getCampaign(params), YReviewAPI.GetCampaignResponseStruct, func);
			},
			getCampaignList: (params, func?): any => {
				return this.callAPI(YReviewAPI.getCampaignList(params), YReviewAPI.GetCampaignListResponseStruct, func);
			},
			getCampaignReviewNum: (params, func?): any => {
				return this.callAPI(YReviewAPI.getCampaignReviewNum(params), YReviewAPI.GetCampaignReviewNumResponseStruct, func);
			},
			getGroups: (params, func?): any => {
				return this.callAPI(YReviewAPI.getGroups(params), YReviewAPI.GetGroupsResponseStruct, func);
			},
			getNotCovered: (params, func?): any => {
				return this.callAPI(YReviewAPI.getNotCovered(params), YReviewAPI.GetNotCoveredResponseStruct, func);
			},
			getNotCovereds: (params, func?): any => {
				return this.callAPI(YReviewAPI.getNotCovereds(params), YReviewAPI.GetNotCoveredsResponseStruct, func);
			},
			getReward: (params, func?): any => {
				return this.callAPI(YReviewAPI.getReward(params), YReviewAPI.GetRewardResponseStruct, func);
			},
			getRewardRequestDetail: (params, func?): any => {
				return this.callAPI(YReviewAPI.getRewardRequestDetail(params), YReviewAPI.GetRewardRequestDetailResponseStruct, func);
			},
			getRewardRequests: (params, func?): any => {
				return this.callAPI(YReviewAPI.getRewardRequests(params), YReviewAPI.GetRewardRequestsResponseStruct, func);
			},
			getRewards: (params, func?): any => {
				return this.callAPI(YReviewAPI.getRewards(params), YReviewAPI.GetRewardsResponseStruct, func);
			},
			getRewardTemplateImage: (params, func?): any => {
				return this.callAPI(YReviewAPI.getRewardTemplateImage(params), YReviewAPI.GetRewardTemplateImageResponseStruct, func);
			},
			getShop: (params, func?): any => {
				return this.callAPI(YReviewAPI.getShop(params), YReviewAPI.GetShopResponseStruct, func);
			},
			getShopByIds: (params, func?): any => {
				return this.callAPI(YReviewAPI.getShopByIds(params), YReviewAPI.GetShopByIdsResponseStruct, func);
			},
			getTargets: (params, func?): any => {
				return this.callAPI(YReviewAPI.getTargets(params), YReviewAPI.GetTargetsResponseStruct, func);
			},
			getTemplate: (params, func?): any => {
				return this.callAPI(YReviewAPI.getTemplate(params), YReviewAPI.GetTemplateResponseStruct, func);
			},
			getTemplates: (params, func?): any => {
				return this.callAPI(YReviewAPI.getTemplates(params), YReviewAPI.GetTemplatesResponseStruct, func);
			},
			getTargetSummary: (params, func?): any => {
				return this.callAPI(YReviewAPI.getTargetSummary(params), YReviewAPI.GetTargetSummaryResponseStruct, func);
			},
			getTopReviewSummary: (params, func?): any => {
				return this.callAPI(YReviewAPI.getTopReviewSummary(params), YReviewAPI.GetTopReviewSummaryResponseStruct, func);
			},
			postCampaign: (params, func?): any => {
				return this.callAPI(YReviewAPI.postCampaign(params), YReviewAPI.PostCampaignResponseStruct, func);
			},
			postGroup: (params, func?): any => {
				return this.callAPI(YReviewAPI.postGroup(params), YReviewAPI.PostGroupResponseStruct, func);
			},
			postNotCovered: (params, func?): any => {
				return this.callAPI(YReviewAPI.postNotCovered(params), YReviewAPI.PostNotCoveredResponseStruct, func);
			},
			postReviews: (params, func?): any => {
				return this.callAPI(YReviewAPI.postReviews(params), YReviewAPI.PostReviewsResponseStruct, func);
			},
			postReward: (params, func?): any => {
				return this.callAPI(YReviewAPI.postReward(params), YReviewAPI.PostRewardResponseStruct, func);
			},
			postTemplate: (params, func?): any => {
				return this.callAPI(YReviewAPI.postTemplate(params), YReviewAPI.PostTemplateResponseStruct, func);
			},
			putCampaign: (params, func?): any => {
				return this.callAPI(YReviewAPI.putCampaign(params), YReviewAPI.PutCampaignResponseStruct, func);
			},
			putGroup: (params, func?): any => {
				return this.callAPI(YReviewAPI.putGroup(params), YReviewAPI.PutGroupResponseStruct, func);
			},
			putNotCovered: (params, func?): any => {
				return this.callAPI(YReviewAPI.putNotCovered(params), YReviewAPI.PutNotCoveredResponseStruct, func);
			},
			putOrderCanSendingMail: (params, func?): any => {
				return this.callAPI(YReviewAPI.putOrderCanSendingMail(params), YReviewAPI.PutOrderCanSendingMailResponseStruct, func);
			},
			putReward: (params, func?): any => {
				return this.callAPI(YReviewAPI.putReward(params), YReviewAPI.PutRewardResponseStruct, func);
			},
			putRewardRequestTransact: (params, func?): any => {
				return this.callAPI(YReviewAPI.putRewardRequestTransact(params), YReviewAPI.PutRewardRequestTransactResponseStruct, func);
			},
			putShop: (params, func?): any => {
				return this.callAPI(YReviewAPI.putShop(params), YReviewAPI.PutShopResponseStruct, func);
			},
			putTemplate: (params, func?): any => {
				return this.callAPI(YReviewAPI.putTemplate(params), YReviewAPI.PutTemplateResponseStruct, func);
			},
			setCampaignPriority: (params, func?): any => {
				return this.callAPI(YReviewAPI.setCampaignPriority(params), YReviewAPI.SetCampaignPriorityResponseStruct, func);
			},
		};
		this.yahoo = {
			beginOAuth: (params, func?): any => {
				return this.callAPI(YahooAPI.beginOAuth(params), YahooAPI.BeginOAuthResponseStruct, func);
			},
			checkLogin: (params, func?): any => {
				return this.callAPI(YahooAPI.checkLogin(params), YahooAPI.CheckLoginResponseStruct, func);
			},
			finishOAuth: (params, func?): any => {
				return this.callAPI(YahooAPI.finishOAuth(params), YahooAPI.FinishOAuthResponseStruct, func);
			},
			getLicenseExpiryDate: (params, func?): any => {
				return this.callAPI(YahooAPI.getLicenseExpiryDate(params), YahooAPI.GetLicenseExpiryDateResponseStruct, func);
			},
			getShop: (params, func?): any => {
				return this.callAPI(YahooAPI.getShop(params), YahooAPI.GetShopResponseStruct, func);
			},
			getShops: (params, func?): any => {
				return this.callAPI(YahooAPI.getShops(params), YahooAPI.GetShopsResponseStruct, func);
			},
			getShopTokenExpiration: (params, func?): any => {
				return this.callAPI(YahooAPI.getShopTokenExpiration(params), YahooAPI.GetShopTokenExpirationResponseStruct, func);
			},
			putShop: (params, func?): any => {
				return this.callAPI(YahooAPI.putShop(params), YahooAPI.PutShopResponseStruct, func);
			},
			searchItems: (params, func?): any => {
				return this.callAPI(YahooAPI.searchItems(params), YahooAPI.SearchItemsResponseStruct, func);
			},
			validateCoupon: (params, func?): any => {
				return this.callAPI(YahooAPI.validateCoupon(params), YahooAPI.ValidateCouponResponseStruct, func);
			},
		};
	}

	private callAPI<T>(req: APIRequest<T>, struct: s.Struct<any, any>, func?: APICallback<T> | undefined): CallAPIRet<T, APICallback<T> | undefined> {
		const source = axios.CancelToken.source();
		const config: AxiosRequestConfigWithResponceType<T> = {
			...req,
			validateStatus: statusValidator,
			cancelToken: source.token,
		};

		let isDone = false;
		const p = axios(config)
			.then(result => {
				isDone = true;

				if (result.status >= 400) {
					this.errorCallback(result);
					return Promise.reject(result);
				}

				result.data = denormalize(result.data);
				s.assert(result.data, struct);

				func?.(null, result);
				return result;
			})
			.catch(err => {
				if (axios.isCancel(err)) {
					return;
				}
				isDone = true;
				func?.(err, err);
				console.error(err);
				return Promise.reject(err);
			});

		if (func) {
			return () => {
				if (isDone) {
					return;
				}
				source.cancel();
			};
		} else {
			return p;
		}
	}
}
export default Api;