import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { PageInfo, RakutenOrder, RewardTargetStatus, rewardTargetStatus, rewardTargetStatusInfos } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { InsertSeparator } from '../../../lib';
import { FormLabel } from '../../molecules/FormLabel';
import { Column, DataTable } from '../../organisms/DataTable';

interface TargetListTableProps {
	rows?: APIResponses.GetTargetsResponseValues[];
	pageInfo?: PageInfo;
	isLoading: boolean;
	handleFilter: () => void;
	methodFilter: UseFormMethods;
	loadMore: () => void;
	onChangeCanSendMail: (target: APIResponses.GetTargetsResponseValues, canSendMail: boolean) => void;
	toOrderDetailURL: (orderNumber: string) => string;
}

export const TargetListTable: React.FunctionComponent<TargetListTableProps> = (props) => {

	// テーブル固有のCSS指定
	// TODO ここ以外で指定できないか要検討
	const tableStyle = css`
		height: 445px;

		td:nth-of-type(1),
		td:nth-of-type(4) {
			flex-direction: column;
		}
	`;

	const handleChangeCanSendMail = (target: APIResponses.GetTargetsResponseValues) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeCanSendMail(target, ev.currentTarget.checked);
	};

	const toShippingDateString = (order: RakutenOrder) => {
		if (order.shippingDate == null) {
			return '未発送';
		}
		return dayjs(order.shippingDate).format('YYYY-MM-DD');
	};
	const toStatusString = (status: RewardTargetStatus) => {
		return rewardTargetStatusInfos[status]?.name ?? status;
	};
	const orderNumberBodyTemplate = (rowData: any) => {
		return <>
			{rowData.orders.map((order: any) =>
				<a key={order.number} href={props.toOrderDetailURL(order.number)} target="_blank" rel="noreferrer">{order.number}</a>
			)}
		</>;
	};
	const campaignNameBodyTemplate = (rowData: any) => {
		return <>
			<InsertSeparator unique>
				{rowData.campaigns.map((campaign: any) => campaign.name)}
			</InsertSeparator>
		</>;
	};
	const shippingBodyTemplate = (rowData: any) => {
		return <>
			{rowData.orders.map((order: any) =>
				<time key={order.number}>{toShippingDateString(order)}</time>
			)}
		</>;
	};
	const hasReviewBodyTemplate = (has: boolean) => {
		return has ? '済' : '未確認';
	};
	const hasItemReviewBodyTemplate = (rowData: any) => {
		return hasReviewBodyTemplate(rowData.hasItemReview);
	};
	const hasShopReviewBodyTemplate = (rowData: any) => {
		return hasReviewBodyTemplate(rowData.hasShopReview);
	};
	const hasSentFollowMailBodyTemplate = (rowData: any) => {
		return rowData.orders.some((order: any) => order.hasSentFollowMail) ? '済' : '未送信';
	};
	const canSendingMailBodyTemplate = (rowData: any) => {
		return (
			<label className="el_toggleBtn">
				<input type="checkbox" checked={rowData.orders.some((order: any) => order.canSendingMail)} onChange={handleChangeCanSendMail(rowData)} />
				<span />
			</label>
		);
	};
	const statusBodyTemplate = (rowData: any) => {
		return <>{toStatusString(rowData.status)}</>;
	}
	const stateFilterElement = (options: any): JSX.Element => {
		const filterForm = <>
			<select name="status" ref={options.register} onChange={() => options.handleFilterToClose()}>
				<option value="">すべて表示</option>
				{rewardTargetStatus.map((status) =>
					<option key={status} value={status}>{rewardTargetStatusInfos[status].name}</option>
				)}
			</select>
		</>;
		return options.createFilterSection(filterForm, '状況でフィルタ');
	}
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	}

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>レビュー待ち状況一覧</FormLabel>
				<DataTable css={tableStyle}
					rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
					loadMore={props.loadMore}
					handleFilter={props.handleFilter} methodFilter={props.methodFilter}
					paginationType="infiniteScroll"
					currentPageReportTemplate={pageReportTemplate}
					>
					<Column style="0 0 138px center center" field="orderNumber"
						filterable
						bodyTemplate={orderNumberBodyTemplate}
						>注文番号</Column>
					<Column style="0 0 170px center center" field="sku">SKU</Column>
					<Column style="0 1 485px center flex-start" field="campaignNames" bodyTemplate={campaignNameBodyTemplate}>キャンペーン名</Column>
					<Column style="0 0 120px center center" field="shippingDate" bodyTemplate={shippingBodyTemplate}>発送日</Column>
					<Column style="0 0 116px center center" field="hasItemReview" bodyTemplate={hasItemReviewBodyTemplate}>商品レビュー</Column>
					<Column style="0 0 144px center center" field="hasShopReview" bodyTemplate={hasShopReviewBodyTemplate}>ショップレビュー</Column>
					<Column style="0 0 130px center center" field="hasSentFollowMail" bodyTemplate={hasSentFollowMailBodyTemplate}>フォローメール</Column>
					<Column style="0 0 102px center center" field="canSendingMail" bodyTemplate={canSendingMailBodyTemplate}>メール送信</Column>
					<Column style="0 0 135px center center" field="status" bodyTemplate={statusBodyTemplate}
						filterable
						filterFormAlign="right"
						filterElement={stateFilterElement}
						>特典申込状況</Column>
				</DataTable>
			</div>
		</>
	);
}
export default TargetListTable;